import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Release v2020.11.23`}</h2>
    <h3>{`Features`}</h3>
    <p><strong parentName="p">{`Device Name in Inventory:`}</strong></p>
    <p>{`When systems are installed with the AWS agent inventory is collected. As part of that inventory, the device name should be collected and stored.`}</p>
    <h3>{`Bug Fixes`}</h3>
    <p><em parentName="p">{`Bugs resolved in this release were found during development.`}</em></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://tfs.carefusion.com/tfs/DefaultCollection/DaVinci/_workItems#id=1174757&triage=true&fullScreen=false&_a=edit"
        }}>{`Bug 1174757`}</a>{`:Step list in admin section only loads 15 steps max`}</p>
    </blockquote>
    <p><em parentName="p">{`Resolution:`}</em>{` Increased limit of 15 items from the Database.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://tfs.carefusion.com/tfs/DefaultCollection/DaVinci/_workItems#id=1174758&triage=true&fullScreen=false&_a=edit"
        }}>{`Bug 1174758`}</a>{`: Server image configuration missing MIME type for .vhd file types`}</p>
    </blockquote>
    <p><em parentName="p">{`Resolution:`}</em>{` Updated PowerShell script that configures IIS for Device Image downloads to add `}<inlineCode parentName="p">{`.vhd`}</inlineCode>{` file type to MIME list.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://tfs.carefusion.com/tfs/DefaultCollection/DaVinci/_workItems#id=1174759&triage=true&fullScreen=false&_a=edit"
        }}>{`Bug 1174759`}</a>{`: Status updates for image download not executing on dev and stage environments`}</p>
    </blockquote>
    <p><em parentName="p">{`Resolution:`}</em>{` Update to User permissions for Status Updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      